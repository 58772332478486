import { FetchPolicy } from '@apollo/client';

import {
    CreateMerchant,
    CreateMerchantInput,
    // DeleteMerchant,
    GetMerchant,
    GetMerchants,
    GetAvailableMerchants,
    UpdateMerchant,
    UpdateMerchantInput,
    MerchantBillListOptions,
    GetMerchantBills
} from '../../common/generated-types';
import {
    CREATE_MERCHANT,
    // DELETE_MERCHANT,
    GET_MERCHANT,
    GET_MERCHANTS,
    GET_AVAILABLE_MERCHANTS,
    UPDATE_MERCHANT,
    GET_MERCHANT_BILLS
} from '../definitions/merchant-definitions';

import { BaseDataService } from './base-data.service';

export class MerchantDataService {
    constructor(private baseDataService: BaseDataService) { }

    getMerchants(take: number = 10, skip: number = 0) {
        return this.baseDataService.query<GetMerchants.Query, GetMerchants.Variables>(
            GET_MERCHANTS,
            {
                options: {
                    take,
                    skip,
                },
            },
        );
    }

    getMerchant(id: string) {
        return this.baseDataService.query<GetMerchant.Query, GetMerchant.Variables>(
            GET_MERCHANT,
            {
                id,
            },
        );
    }

    getAvailableMerchants(take: number = 10, skip: number = 0) {
        return this.baseDataService.query<GetAvailableMerchants.Query, GetAvailableMerchants.Variables>(
            GET_AVAILABLE_MERCHANTS,
            {
                options: {
                    take,
                    skip,
                },
            },
        );
    }

    createMerchant(input: CreateMerchantInput) {
        return this.baseDataService.mutate<CreateMerchant.Mutation, CreateMerchant.Variables>(
            CREATE_MERCHANT,
            { input },
        );
    }

    updateMerchant(input: UpdateMerchantInput) {
        return this.baseDataService.mutate<UpdateMerchant.Mutation, UpdateMerchant.Variables>(
            UPDATE_MERCHANT,
            { input },
        );
    }

    getMerchantBills(take: number = 10, skip: number = 0) {
        return this.baseDataService.query<GetMerchantBills.Query, GetMerchantBills.Variables>(
            GET_MERCHANT_BILLS,
            {
                options: {
                    take,
                    skip,
                },
            },
        );
    }
}
