<ng-select
    [items]="facetValues"
    [addTag]="false"
    [hideSelected]="true"
    bindValue="id"
    multiple="true"
    appendTo="body"
    bindLabel="name"
    [disabled]="disabled || readonly"
    [ngModel]="value"
    (change)="onChange($event)"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <vdr-facet-value-chip
            [facetValue]="item.value"
            [removable]="!readonly"
            (remove)="clear(item)"
        ></vdr-facet-value-chip>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <vdr-facet-value-chip [facetValue]="item.value" [removable]="false"></vdr-facet-value-chip>
    </ng-template>
</ng-select>
