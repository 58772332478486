import { Route } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AppShellComponent, AuthGuard } from '@vendure/admin-ui/core';

export const routes: Route[] = [
    { path: 'login', loadChildren: () => import('../lib/login/src/login.module').then(m => m.LoginModule) },
    {
        path: '',
        canActivate: [AuthGuard],
        component: AppShellComponent,
        data: {
            breadcrumb: _('breadcrumb.dashboard'),
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('../lib/dashboard/src/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'merchants',
                loadChildren: () => import('../lib/merchant/src/merchant.module').then(m => m.MerchantModule),
            },
            {
                path: 'catalog',
                loadChildren: () => import('../lib/catalog/src/catalog.module').then(m => m.CatalogModule),
            },
            {
                path: 'customer',
                loadChildren: () => import('../lib/customer/src/customer.module').then(m => m.CustomerModule),
            },
            {
                path: 'orders',
                loadChildren: () => import('../lib/order/src/order.module').then(m => m.OrderModule),
            },
            {
                path: 'marketing',
                loadChildren: () => import('../lib/marketing/src/marketing.module').then(m => m.MarketingModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('../lib/settings/src/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'system',
                loadChildren: () => import('../lib/system/src/system.module').then(m => m.SystemModule),
            },
        ],
    },
];
