import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

/**
 * The purpose of this file is to simply mark each available language code (as defined in the
 * LanguageCode GraphQL enum) with the `mark-for-extraction` function, so that when running
 * the "extract-translations" script, the language translation tokens will be extracted.
 */

_('lang.af');
_('lang.ak');
_('lang.sq');
_('lang.am');
_('lang.ar');
_('lang.hy');
_('lang.as');
_('lang.az');
_('lang.bm');
_('lang.bn');
_('lang.eu');
_('lang.be');
_('lang.bs');
_('lang.br');
_('lang.bg');
_('lang.my');
_('lang.ca');
_('lang.ce');
_('lang.zh');
_('lang.zh_Hans');
_('lang.zh_Hant');
_('lang.cu');
_('lang.kw');
_('lang.co');
_('lang.hr');
_('lang.cs');
_('lang.da');
_('lang.nl');
_('lang.nl_BE');
_('lang.dz');
_('lang.en');
_('lang.en_AU');
_('lang.en_CA');
_('lang.en_GB');
_('lang.en_US');
_('lang.eo');
_('lang.et');
_('lang.ee');
_('lang.fo');
_('lang.fi');
_('lang.fr');
_('lang.fr_CA');
_('lang.fr_CH');
_('lang.ff');
_('lang.gl');
_('lang.lg');
_('lang.ka');
_('lang.de');
_('lang.de_AT');
_('lang.de_CH');
_('lang.el');
_('lang.gu');
_('lang.ht');
_('lang.ha');
_('lang.he');
_('lang.hi');
_('lang.hu');
_('lang.is');
_('lang.ig');
_('lang.id');
_('lang.ia');
_('lang.ga');
_('lang.it');
_('lang.ja');
_('lang.jv');
_('lang.kl');
_('lang.kn');
_('lang.ks');
_('lang.kk');
_('lang.km');
_('lang.ki');
_('lang.rw');
_('lang.ko');
_('lang.ku');
_('lang.ky');
_('lang.lo');
_('lang.la');
_('lang.lv');
_('lang.ln');
_('lang.lt');
_('lang.lu');
_('lang.lb');
_('lang.mk');
_('lang.mg');
_('lang.ms');
_('lang.ml');
_('lang.mt');
_('lang.gv');
_('lang.mi');
_('lang.mr');
_('lang.mn');
_('lang.ne');
_('lang.nd');
_('lang.se');
_('lang.nb');
_('lang.nn');
_('lang.ny');
_('lang.or');
_('lang.om');
_('lang.os');
_('lang.ps');
_('lang.fa');
_('lang.fa_AF');
_('lang.pl');
_('lang.pt');
_('lang.pt_BR');
_('lang.pt_PT');
_('lang.pa');
_('lang.qu');
_('lang.ro');
_('lang.ro_MD');
_('lang.rm');
_('lang.rn');
_('lang.ru');
_('lang.sm');
_('lang.sg');
_('lang.sa');
_('lang.gd');
_('lang.sr');
_('lang.sn');
_('lang.ii');
_('lang.sd');
_('lang.si');
_('lang.sk');
_('lang.sl');
_('lang.so');
_('lang.st');
_('lang.es');
_('lang.es_ES');
_('lang.es_MX');
_('lang.su');
_('lang.sw');
_('lang.sw_CD');
_('lang.sv');
_('lang.tg');
_('lang.ta');
_('lang.tt');
_('lang.te');
_('lang.th');
_('lang.bo');
_('lang.ti');
_('lang.to');
_('lang.tr');
_('lang.tk');
_('lang.uk');
_('lang.ur');
_('lang.ug');
_('lang.uz');
_('lang.vi');
_('lang.vo');
_('lang.cy');
_('lang.fy');
_('lang.wo');
_('lang.xh');
_('lang.yi');
_('lang.yo');
_('lang.zu');
