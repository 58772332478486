<ng-template vdrDialogTitle>{{ title | translate:translationVars }}</ng-template>
{{ body | translate:translationVars }}
<ng-template vdrDialogButtons>
    <ng-container *ngFor="let button of buttons">
        <button
            class="btn"
            [class.btn-primary]="button.type === 'primary'"
            [class.btn-danger]="button.type === 'danger'"
            (click)="resolveWith(button.returnValue)"
        >
            {{ button.label | translate:translationVars }}
        </button>
    </ng-container>
</ng-template>
