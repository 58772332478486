import {
    GetLicensee,
    AssignLicenseeToChannel,
    AssignLicenseeToChannelInput,
    RemoveLicenseeFromChannel,
    RemoveLicenseeFromChannelInput,
} from '../../common/generated-types';
import {
    GET_LICENSEE,
    ASSIGN_LICENSEE_TO_CHANNEL,
    REMOVE_LICENSEE_FROM_CHANNEL,
} from '../definitions/licensee-definitions';

import { BaseDataService } from './base-data.service';

export class LicenseeDataService {
    constructor(private baseDataService: BaseDataService) {}

    getLicensee() {
        return this.baseDataService.query<GetLicensee.Query, GetLicensee.Variables>(
            GET_LICENSEE,
        );
    }

    assignLicenseeToChannel(input: AssignLicenseeToChannelInput) {
        return this.baseDataService.mutate<AssignLicenseeToChannel.Mutation, AssignLicenseeToChannel.Variables>(
            ASSIGN_LICENSEE_TO_CHANNEL,
            { input },
        );
    }

    removeLicenseeFromChannel(input: RemoveLicenseeFromChannelInput) {
        return this.baseDataService.mutate<RemoveLicenseeFromChannel.Mutation, RemoveLicenseeFromChannel.Variables>(
            REMOVE_LICENSEE_FROM_CHANNEL,
            { input },
        );
    }
}
