import { gql } from 'apollo-angular';

import { CHANNEL_FRAGMENT } from './settings-definitions';

export const LICENSEE_FRAGMENT = gql`
    fragment Licensee on Licensee {
        id
        createdAt
        updatedAt
        name
        description
        channels {
          ...Channel
        }
    }
    ${CHANNEL_FRAGMENT}
`;

export const GET_LICENSEE = gql`
    query GetLicensee {
        licensee {
            ...Licensee
        }
    }
    ${LICENSEE_FRAGMENT}
`;

export const ASSIGN_LICENSEE_TO_CHANNEL = gql`
    mutation AssignLicenseeToChannel($input: AssignLicenseeToChannelInput!) {
        assignLicenseeToChannel(input: $input) {
            ...Licensee
        }
    }
    ${LICENSEE_FRAGMENT}
`;

export const REMOVE_LICENSEE_FROM_CHANNEL = gql`
    mutation RemoveLicenseeFromChannel($input: RemoveLicenseeFromChannelInput!) {
        removeLicenseeFromChannel(input: $input) {
            ...Licensee
        }
    }
    ${LICENSEE_FRAGMENT}
`;
