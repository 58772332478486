<nav class="sidenav" [clr-nav-level]="2">
    <section class="sidenav-content">
        <ng-container *ngFor="let section of navBuilderService.navMenuConfig$ | async">
            <section
                class="nav-group"
                [attr.data-section-id]="section.id"
                [class.collapsible]="section.collapsible"
                *ngIf="shouldDisplayLink(section)"
            >
                <ng-container *ngIf="navBuilderService.sectionBadges[section.id] | async as sectionBadge">
                    <div *ngIf="sectionBadge !== 'none'" class="status-badge" [class]="sectionBadge"></div>
                </ng-container>
                <input [id]="section.id" type="checkbox" [checked]="section.collapsedByDefault" />
                <label [for]="section.id">{{ section.label | translate }}</label>
                <ul class="nav-list">
                    <ng-container *ngFor="let item of section.items">
                        <li *ngIf="shouldDisplayLink(item)">
                            <a
                                class="nav-link"
                                [attr.data-item-id]="section.id"
                                [routerLink]="getRouterLink(item)"
                                routerLinkActive="active"
                            >
                                <ng-container *ngIf="item.statusBadge | async as itemBadge">
                                    <div
                                        *ngIf="itemBadge.type !== 'none'"
                                        class="status-badge"
                                        [class]="itemBadge.type"
                                    ></div>
                                </ng-container>
                                <clr-icon [attr.shape]="item.icon || 'block'" size="20"></clr-icon>
                                {{ item.label | translate }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </section>
        </ng-container>
    </section>
</nav>
