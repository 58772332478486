<form [formGroup]="formGroup">
    <clr-input-container>
        <label>{{ 'customer.full-name' | translate }}</label>
        <input formControlName="fullName" type="text" clrInput />
    </clr-input-container>

    <div class="clr-row">
        <div class="clr-col-md-4">
            <clr-input-container>
                <label>{{ 'customer.street-line-1' | translate }}</label>
                <input formControlName="streetLine1" type="text" clrInput />
            </clr-input-container>
        </div>
        <div class="clr-col-md-4">
            <clr-input-container>
                <label>{{ 'customer.street-line-2' | translate }}</label>
                <input formControlName="streetLine2" type="text" clrInput />
            </clr-input-container>
        </div>
    </div>
    <div class="clr-row">
        <div class="clr-col-md-4">
            <clr-input-container>
                <label>{{ 'customer.city' | translate }}</label>
                <input formControlName="city" type="text" clrInput />
            </clr-input-container>
        </div>
        <div class="clr-col-md-4">
            <clr-input-container>
                <label>{{ 'customer.province' | translate }}</label>
                <input formControlName="province" type="text" clrInput />
            </clr-input-container>
        </div>
    </div>
    <div class="clr-row">
        <div class="clr-col-md-4">
            <clr-input-container>
                <label>{{ 'customer.postal-code' | translate }}</label>
                <input formControlName="postalCode" type="text" clrInput />
            </clr-input-container>
        </div>
        <div class="clr-col-md-4">
            <clr-input-container>
                <label>{{ 'customer.country' | translate }}</label>
                <select name="countryCode" formControlName="countryCode" clrInput clrSelect>
                    <option *ngFor="let country of availableCountries" [value]="country.code">
                        {{ country.name }}
                    </option>
                </select>
            </clr-input-container>
        </div>
    </div>
    <clr-input-container>
        <label>{{ 'customer.phone-number' | translate }}</label>
        <input formControlName="phoneNumber" type="text" clrInput />
    </clr-input-container>
    <section formGroupName="customFields" *ngIf="formGroup.get('customFields') as customFieldsGroup">
        <label>{{ 'common.custom-fields' | translate }}</label>
        <ng-container *ngFor="let customField of customFields">
            <vdr-custom-field-control
                entityName="Address"
                [customFieldsFormGroup]="customFieldsGroup"
                [customField]="customField"
            ></vdr-custom-field-control>
        </ng-container>
    </section>
</form>
