<vdr-dropdown>
    <button class="btn btn-link trigger" vdrDropdownTrigger>
        <span class="user-name">{{ userName }}</span>
        <clr-icon shape="user" size="24"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
    </button>
    <vdr-dropdown-menu vdrPosition="bottom-right">
        <a [routerLink]="['/settings', 'profile']" vdrDropdownItem>
            <clr-icon shape="user" class="is-solid"></clr-icon> {{ 'settings.profile' | translate }}
        </a>
        <ng-container *ngIf="1 < availableLanguages.length">
            <button
                type="button"
                vdrDropdownItem
                (click)="selectUiLanguage.emit()"
                [title]="'common.select-display-language' | translate"
            >
                <clr-icon shape="language"></clr-icon> {{ 'lang.' + uiLanguage | translate }}
            </button>
        </ng-container>
        <div class="dropdown-divider"></div>
        <button type="button" vdrDropdownItem (click)="logOut.emit()">
            <clr-icon shape="logout"></clr-icon> {{ 'common.log-out' | translate }}
        </button>
    </vdr-dropdown-menu>
</vdr-dropdown>
