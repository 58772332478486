<clr-main-container>
    <clr-header>
        <div class="branding">
            <a [routerLink]="['/']">
                <svg class="logo" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32" style="mask-type: alpha;"><path d="M1.03 11.4c.27-5.396.405-8.095 2.142-9.747C4.91 0 7.612 0 13.015 0h5.97c5.404 0 8.105 0 9.843 1.653 1.737 1.652 1.872 4.35 2.142 9.748l.4 8c.296 5.913.444 8.87-1.33 10.734C28.266 32 25.306 32 19.385 32h-6.77c-5.92 0-8.881 0-10.655-1.865C.186 28.271.334 25.315.63 19.401l.4-8Z" fill="#fff"></path></mask><g mask="url(#a)" stroke="#15222F" stroke-width="3.847"><circle cx="41.5" cy="66.5" r="54.577" fill="#85DFAE"></circle><circle cx="-9.5" cy="66.5" r="54.577" fill="#ACDF87"></circle><circle cx="-27.5" cy="67.5" r="54.577" fill="#43AF75"></circle></g><mask id="b" fill="#fff"><path d="M8 8a8 8 0 0 1 16 0"></path></mask><path d="M4.153 8v3.847h7.694V8H4.153Zm16 0v3.847h7.694V8h-7.694Zm-8.306 0A4.154 4.154 0 0 1 16 3.847v-7.694C9.458-3.847 4.153 1.458 4.153 8h7.694ZM16 3.847A4.154 4.154 0 0 1 20.153 8h7.694c0-6.542-5.305-11.847-11.847-11.847v7.694Z" fill="#15222F" mask="url(#b)"></path></svg>
                Grasslands
            </a>
        </div>
        <div class="header-nav"></div>
        <div class="header-actions">
            <vdr-channel-switcher *vdrIfMultichannel></vdr-channel-switcher>
            <vdr-user-menu [userName]="userName$ | async"
                           [uiLanguage]="uiLanguage$ | async"
                           [availableLanguages]="availableLanguages"
                           (selectUiLanguage)="selectUiLanguage()"
                           (logOut)="logOut()"></vdr-user-menu>
        </div>
    </clr-header>
    <nav class="subnav"><vdr-breadcrumb></vdr-breadcrumb></nav>

    <div class="content-container">
        <div class="content-area"><router-outlet></router-outlet></div>
        <vdr-main-nav></vdr-main-nav>
    </div>
</clr-main-container>